@import "https://fonts.googleapis.com/css2?family=Roboto&display=swap";
@font-face {
  font-family: AkzidenzGroteskPro;
  src: url("Akzidenz Grotesk Pro Light Ext Regular.74cfef7f.otf") format("opentype");
}

* {
  color: inherit;
  box-sizing: border-box;
  border: none;
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
}

:root {
  --background-color: #242321;
  --background-color-translucid: #000c;
  --text-color-gray: #868686;
  --btn-open-width: 28px;
  --btn-open-height: 20px;
  --btn-close-width: 20px;
  --btn-close-height: 20px;
  --text-title-spacing: 2px;
  --text-subtitle-spacing: 2px;
  --text-title-size: 19px;
  --text-subtitle-size: 17px;
  --text-subtitle-large-size: 22px;
  --text-normal-size: 18px;
  --nav-title-size: 38px;
  --nav-item-size: 24px;
  --padding-x: 25px;
  --text-img: 20px;
}

html, body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  background-color: var(--background-color);
  color: #fff;
  font-family: Roboto, sans-serif;
  position: relative;
  overflow-x: hidden;
}

.index {
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  display: flex;
}

.footer {
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 50px;
  display: flex;
}

.text-gray {
  color: var(--text-color-gray);
}

.text-title {
  font-size: var(--text-title-size);
  letter-spacing: var(--text-title-spacing);
}

.text-subtitle {
  font-size: var(--text-subtitle-size);
  letter-spacing: var(--text-subtitle-spacing);
}

.text-normal {
  font-size: var(--text-normal-size);
}

.text-subtitle-large {
  font-size: var(--text-subtitle-large-size);
}

.webspace {
  font-family: AkzidenzGroteskPro;
}

.btn {
  background-color: inherit;
  cursor: pointer;
}

.header {
  padding: 30px var(--padding-x);
  justify-content: space-between;
  display: flex;
  position: sticky;
}

@media (orientation: portrait) and (width >= 500px) {
  :root {
    --nav-title-size: 64px;
    --nav-item-size: 35px;
  }
}

@media (width >= 1024px) {
  :root {
    --nav-title-size: 37px;
    --nav-item-size: 22px;
    --text-title-size: 24px;
    --text-subtitle-size: 22px;
  }

  html {
    position: relative;
  }

  body {
    position: static;
  }

  .header-titles {
    align-items: flex-end;
    gap: 14px;
    display: flex;
  }

  .header-titles .text-subtitle {
    transform: translateY(1px);
  }
}

@media (width >= 1360px) {
  :root {
    --text-img: 20px;
  }
}

@media (width >= 1920px) {
  :root {
    --nav-title-size: 47px;
    --nav-item-size: 33px;
    --text-normal-size: 22px;
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
/*# sourceMappingURL=index.776ad233.css.map */
