@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

@font-face {
    font-family: AkzidenzGroteskPro;
    src: url("../assets/fonts/Akzidenz Grotesk Pro Light Ext Regular.otf") format("opentype");
}


/* Reset CSS */

* {
    margin: 0;
    padding: 0;
    color: inherit;
    text-decoration: none;
    list-style: none;
    border: none;
    box-sizing: border-box;
}


/* Variables */

:root {
    --background-color: #242321;
    --background-color-translucid: rgba(0, 0, 0, 0.8);
    --text-color-gray: #868686;
    --btn-open-width: 28px;
    --btn-open-height: 20px;
    --btn-close-width: 20px;
    --btn-close-height: 20px;
    --text-title-spacing: 2px;
    --text-subtitle-spacing: 2px;
    --text-title-size: 19px;
    --text-subtitle-size: 17px;
    --text-subtitle-large-size: 22px;
    --text-normal-size: 18px;
    --nav-title-size: 38px;
    --nav-item-size: 24px;
    --padding-x: 25px;
    --text-img: 20px;
}


/* Html, Body */

html,
body {
    overflow-x: hidden;
    max-width: 100vw;
}

body {
    background-color: var(--background-color);
    font-family: 'Roboto', sans-serif;
    color: white;
    overflow-x: hidden;
    position: relative;
}

/* Index */

.index {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.footer {
    padding-bottom: 50px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

/* Text */

.text-gray {
    color: var(--text-color-gray);
}

.text-title {
    font-size: var(--text-title-size);
    letter-spacing: var(--text-title-spacing);
}

.text-subtitle {
    font-size: var(--text-subtitle-size);
    letter-spacing: var(--text-subtitle-spacing);
}

.text-normal {
    font-size: var(--text-normal-size);
}

.text-subtitle-large {
    font-size: var(--text-subtitle-large-size);
}

.webspace {
    font-family: AkzidenzGroteskPro;
}

/* Others */

.btn {
    background-color: inherit;
    cursor: pointer;
}

.header {
    display: flex;
    position: sticky;
    justify-content: space-between;
    padding: 30px var(--padding-x);
}

@media (orientation: portrait) and (min-width: 500px) {
     :root {
        --nav-title-size: 64px;
        --nav-item-size: 35px;
    }
}

@media (min-width: 1024px) {
     :root {
        --nav-title-size: 37px;
        --nav-item-size: 22px;
        --text-title-size: 24px;
        --text-subtitle-size: 22px;
    }
    html {
        position: relative;
    }
    body {
        position: static;
    }
    .header-titles {
        display: flex;
        align-items: flex-end;
        gap: 14px;
    }
    .header-titles .text-subtitle {
        transform: translateY(1px);
    }
}

@media (min-width: 1360px) {
     :root {
        --text-img: 20px;
    }
}

@media (min-width: 1920px) {
     :root {
        --nav-title-size: 47px;
        --nav-item-size: 33px;
        --text-normal-size: 22px;
    }
}


@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}